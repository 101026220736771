import React, { useContext, useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { toSlug } from "../helpers";
import { StoreContext } from "../context";
import {
  mainWhite,
  mainBlue,
  mainBlack,
  dirtyWhite,
  screen,
} from "../components/variables";
import { motion } from "framer-motion";
import IconCompostable from "../images/svg/icon-compostable.svg";
import IconRecycling from "../images/svg/icon-recycling.svg";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import Slider from "react-slick";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import FeaturedProductList from "../components/featured-product-list";
import Tag from "../components/tag/title";
import Spinner from "../images/svg/spinner.svg";
import Ad from "../components/ad";

const Wrapper = styled.div`
  background: ${mainWhite};

  padding: 70px 0 0 0;
  @media ${screen.xsmall} {
    padding: 90px 0 0 0;
    min-height: 70vh;
  }

  .flex {
    @media ${screen.small} {
      display: flex;
    }

    .col {
      &--left {
        @media ${screen.small} {
          padding: 0 50px 70px 0;
          width: 50%;
          opacity: 1;
        }
        @media ${screen.medium} {
          padding: 0 100px 90px 0;
          width: 60%;
        }

        .title {
          color: ${mainBlue};
          font-weight: 500;
          margin: 0 0 10px 0;
          position: relative;
          display: inline-block;
          font-size: 1.4rem;
          @media ${screen.xsmall} {
            font-size: 1.95rem;
          }
          @media ${screen.small} {
            font-size: 2.15rem;
          }
        }

        .subtitle {
          font-size: 0.95rem;
          @media ${screen.xsmall} {
            font-size: 1.05rem;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }
        }

        .gallery {
          margin: 50px 0 0 0;
          @media ${screen.small} {
            margin: 0;
          }

          .slides {
            .slick-thumb {
              display: flex !important;
              flex-wrap: wrap;
              justify-content: center;

              li {
                width: 72px;
                border: 2px #e8e8ea solid;
                cursor: pointer;
                margin: 0 5px;
                transition: border 0.2s ease-in-out;
                @media ${screen.xsmall} {
                  margin: 0 6px;
                  width: 94px;
                }
              }

              .slick-active {
                border: 2px rgba(32, 33, 33, 0.55) solid;
              }
            }

            .gallery-arrow {
              position: absolute;
              top: calc(50% - 27px);
              transform: translateY(-50%);
              z-index: 3;
              font-size: 2.2rem;
              color: ${mainBlue};
              cursor: pointer;
              @media ${screen.xsmall} {
                font-size: 3.5rem;
                top: calc(50% - 24px);
              }
              @media ${screen.small} {
                font-size: 2.9rem;
              }
              @media ${screen.medium} {
                font-size: 3.1rem;
              }

              &--prev {
                left: -13px;
                @media ${screen.xsmall} {
                  left: -20px;
                }
              }
              &--next {
                right: -13px;
                @media ${screen.xsmall} {
                  font-size: 3.5rem;
                  right: -20px;
                }
              }
            }

            div {
              outline: none;
            }
          }
        }
      }

      &--right {
        margin: 50px 0 0 0;
        @media ${screen.small} {
          width: 50%;
          margin: 70px 0 0 0;
        }
        @media ${screen.medium} {
          width: 40%;
          margin: 90px 0 0 0;
        }

        .tab {
          display: flex;
          position: relative;
          margin: 0 0 25px 0;

          &__btn {
            border-bottom: 3px #e8e8ea solid;
            font-size: 0.98rem;
            padding: 0 13px 3px 13px;
            position: relative;
            z-index: 2;
            cursor: pointer;

            &--active {
              border-bottom: 3px ${mainBlue} solid;
            }
          }

          &::after {
            content: "";
            position: absolute;
            height: 3px;
            bottom: 0;
            background: #e8e8ea;
            z-index: 1;
          }
        }

        .description {
          p {
            font-size: 0.95rem;
            margin: 0 0 20px 0;
            &:last-child {
              margin: 0;
            }
          }

          .file-link {
            color: ${mainBlack};
            font-size: 0.95rem;
            margin: 20px 0 0 0;
            display: inline-block;
            text-decoration: underline;
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainBlue};
              }
            }
          }

          .tags {
            margin: 30px 0 0 0;
            @media ${screen.xsmall} {
              display: flex;
            }

            &__each {
              font-size: 0.82rem;
              color: ${mainBlue};
              display: flex;
              align-items: center;
              margin: 0 0 15px 0;
              font-weight: 500;
              @media ${screen.xsmall} {
                font-size: 0.92rem;
                margin: 0 60px 0 0;
              }

              svg {
                width: 30px;
                height: 30px;
                margin: 0 10px 0 0;
              }
            }
          }
        }

        .specifications {
          ul {
            @media ${screen.xsmall} {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -24px;
            }

            li {
              font-size: 0.92rem;
              margin: 0 0 18px 0;
              line-height: 1.27;
              @media ${screen.xsmall} {
                width: calc(50% - 48px);
                margin: 0 24px;
                line-height: 1.4;
              }

              .wrap {
                display: flex;
                border-bottom: 1px ${mainBlack} solid;
                padding: 17px 0 4px 0;

                strong {
                  font-weight: 600;
                  padding: 0 13px 0 0;
                }

                span {
                  font-weight: 300;
                }
              }
            }
          }
        }

        .dropdown {
          position: relative;
          margin: 45px 0 0 0;

          .select {
            display: block;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            -webkit-border-radius: 0px;
            text-indent: 1px;
            text-overflow: "";
            width: 100%;
            padding: 12px 14px;
            outline: none;
            background: #f0f0f0;
            color: ${mainBlack};
            border: 0;
            border-radius: none;
            font-size: 15px;
            cursor: pointer;
          }

          svg {
            font-size: 1.7rem;
            color: red;
            position: absolute;
            right: 7px;
            top: 50%;
            transform: translateY(-50%);
            color: ${mainBlack};
          }
        }

        .min-order {
          margin: 30px 0;

          p {
            line-height: 1.8;
          }
        }

        .qty-quote {
          padding: 0 0 90px 0;
          @media ${screen.small} {
            padding: 0;
          }

          .unit {
            font-weight: 500;
            font-size: 0.75rem;
          }

          .flex {
            display: flex;
            margin: 5px 0 0 0;

            .input {
              all: unset;
              width: 80px;
              border: 0;
              border-radius: 0;
              border: 1px ${mainBlack} solid;
              outline: none;
              font-size: 16px;
              text-align: center;
              line-height: 30px;

              &--disable {
                cursor: not-allowed;
                opacity: 0.5;
                pointer-events: none;
              }
            }

            .btn {
              background: ${mainBlue};
              border: 2px ${mainBlue} solid;
              color: ${mainWhite};
              width: 100%;
              text-align: center;
              padding: 10px 0;
              margin: 0 0 0 10px;
              opacity: ${(props) => (props.isOrderExist ? 0.5 : 1)};
              pointer-events: ${(props) =>
                props.isOrderExist ? "none" : "all"};
              @media ${screen.withCursor} {
                &:hover {
                  background: none;
                  color: ${mainBlue};
                }
              }
            }
          }
        }
      }
    }
  }

  .related-product {
    background: ${dirtyWhite};
    padding: 60px 0 35px 0;
    position: relative;
    bottom: -1px;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    .heading {
      color: ${mainBlue};
      margin: 0 0 25px 0;
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        margin: 0 0 50px 0;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }
  }

  .ad-cta {
    padding: 0 0 70px 0;
    background: ${dirtyWhite};
    background: ${(props) =>
      props.isRelatedProductList ? dirtyWhite : mainWhite};
    @media ${screen.xsmall} {
      display: none;
    }
  }

  #loading-add {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: absolute;
      background: linear-gradient(
        to left bottom,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.35)
      );
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      svg {
        width: 190px;
        height: 190px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background: rgba(255, 255, 255, 0.19);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      backdrop-filter: blur(10px);
    }
  }
`;

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="gallery-arrow gallery-arrow--prev" onClick={onClick}>
      <MdKeyboardArrowLeft />
    </div>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="gallery-arrow gallery-arrow--next" onClick={onClick}>
      <MdKeyboardArrowRight />
    </div>
  );
}

const SingleProductPage = ({ data }) => {
  const {
    title,
    item_number,
    compostable,
    recycle_and_reuse,
    title_tag,
    meta_description,
    description,
    pdf_file,
    specifications,
    minimum_order_quantity,
    related_products_list,
    order_in_lots_of,
    gallery,
    highlighted_specification_label,
    highlighted_specification_value,
    related_products_dropdown,
    tag,
    kind,
  } = data.content.data;

  const singleImgUrl =
    gallery.length !== 0 &&
    gallery[0].image &&
    gallery[0].image.gatsbyImageData &&
    gallery[0].image.gatsbyImageData.images.fallback.src;

  const [isDescription, setIsDescription] = useState(true);

  const [isAdding, setIsAdding] = useState(false);

  const [quantity, setQuantity] = useState(minimum_order_quantity);
  const { addOrder, isOrderExist } = useContext(StoreContext);

  const isSpecificaations = specifications.length && specifications[0].value;

  const isDropdown =
    related_products_dropdown.length &&
    related_products_dropdown[0].product.document;

  const isRelatedProductList =
    related_products_list.length && related_products_list[0].product.document;

  const settings = {
    customPaging: function (i) {
      return (
        <div>
          <GatsbyImage
            image={gallery[i].image.gatsbyImageData}
            alt={gallery[i].alt || "Gallery"}
          />
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Layout rotatedSquareColour={!isRelatedProductList && mainWhite}>
      <Seo
        title={title_tag || title.text}
        description={meta_description}
        image={singleImgUrl}
        productInfo={{
          name: title.text,
          image: singleImgUrl,
          category: kind.document ? kind.document.data.title.text : "Others",
          description: description.text,
        }}
      />
      <Wrapper
        isRelatedProductList={isRelatedProductList}
        isOrderExist={isOrderExist(item_number.text)}
      >
        <div className="inner-wrapper flex">
          <div className="col col--left">
            <header>
              <h1 className="title">
                <Tag label={tag} /> {title.text}
              </h1>
              <p className="subtitle">Item Number: #{item_number.text}</p>
            </header>

            <div className="gallery">
              <Slider className="slides" {...settings}>
                {gallery.map((item, i) => (
                  <div className="each-slide" key={i}>
                    {item.image && item.image.gatsbyImageData ? (
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt || "Gallery"}
                      />
                    ) : (
                      <StaticImage
                        src="../images/fallback-gallery.jpg"
                        alt="Thumbnail"
                      />
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="col col--right">
            <div className="tab">
              <h6
                className={
                  isDescription ? "tab__btn tab__btn--active" : "tab__btn"
                }
                onClick={() => {
                  setIsDescription(true);
                }}
              >
                Description
              </h6>
              {isSpecificaations && (
                <h6
                  className={
                    !isDescription ? "tab__btn tab__btn--active" : "tab__btn"
                  }
                  onClick={() => {
                    setIsDescription(false);
                  }}
                >
                  Specifications
                </h6>
              )}
            </div>
            {isDescription && (
              <div className="description">
                <div dangerouslySetInnerHTML={{ __html: description.html }} />

                {pdf_file.url && (
                  <a
                    className="file-link"
                    href={pdf_file.url}
                    target="_blank"
                    title="View PDF version"
                  >
                    Download Product PDF
                  </a>
                )}

                <div className="tags">
                  {compostable && (
                    <div className="tags__each">
                      <IconCompostable />
                      Compostable
                    </div>
                  )}

                  {recycle_and_reuse && (
                    <div className="tags__each">
                      <IconRecycling />
                      Recycle and Reuse
                    </div>
                  )}
                </div>
              </div>
            )}

            {!isDescription && (
              <div className="specifications">
                <ul>
                  {specifications.map((spec) => (
                    <li key={spec.value}>
                      <span className="wrap">
                        <strong>{spec.label}</strong> <span>{spec.value}</span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {!!isDropdown && (
              <div className="dropdown">
                <select
                  onChange={(e) => {
                    setIsAdding(true);

                    setTimeout(() => {
                      navigate(`/products/${e.target.value}`);
                    }, 400);
                  }}
                  className="select"
                >
                  <option>
                    {highlighted_specification_value}{" "}
                    {highlighted_specification_label &&
                      `(${highlighted_specification_label})`}
                  </option>

                  {related_products_dropdown.map(
                    (item, i) =>
                      item.product.document && (
                        <option
                          value={toSlug(item.product.document.data.title.text)}
                          key={item.product.document.id + i}
                        >
                          {
                            item.product.document.data
                              .highlighted_specification_value
                          }{" "}
                          {item.product.document.data
                            .highlighted_specification_label &&
                            `(${item.product.document.data.highlighted_specification_label})`}
                        </option>
                      )
                  )}
                </select>

                <MdKeyboardArrowDown />
              </div>
            )}

            <div className="min-order">
              {minimum_order_quantity && (
                <p>
                  Minimum Order Quantity:{" "}
                  <strong>{minimum_order_quantity} Units</strong>
                </p>
              )}
              {order_in_lots_of && (
                <p>
                  Order in Lots of: <strong>{order_in_lots_of} Units</strong>
                </p>
              )}
            </div>

            <div className="qty-quote">
              <span className="unit">UNITS</span>
              <div className="flex">
                <input
                  value={quantity > 0 ? quantity : ""}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  className={
                    isOrderExist(item_number.text)
                      ? "input input--disable"
                      : "input"
                  }
                  type="text"
                />
                <motion.button
                  whileTap={{ scale: 0.96 }}
                  onClick={() => {
                    addOrder({
                      id: item_number.text,
                      name: title.text,
                      quantity: quantity,
                      image: gallery.length && gallery[0].image.gatsbyImageData,
                      minOrder: minimum_order_quantity,
                      kind: kind.document
                        ? kind.document.data.title.text
                        : "Others",
                    });
                  }}
                  className="btn"
                >
                  {isOrderExist(item_number.text) ? "ADDED" : "ADD TO QUOTE"}
                </motion.button>
              </div>
            </div>
          </div>
        </div>

        {!!isRelatedProductList && (
          <section className="related-product">
            <div className="inner-wrapper">
              <h3 className="heading">Related Products</h3>
              <FeaturedProductList template products={related_products_list} />
            </div>
          </section>
        )}

        <div className="ad-cta">
          <div className="inner-wrapper">
            <Ad />
          </div>
        </div>

        {isAdding && (
          <div id="loading-add">
            <span className="icon">
              <Spinner />
            </span>
          </div>
        )}
      </Wrapper>
    </Layout>
  );
};

export default SingleProductPage;

export const dataQuery = graphql`
  query ($id: String!) {
    content: prismicProduct(id: { eq: $id }) {
      data {
        compostable
        description {
          html
          text
        }
        pdf_file {
          url
        }
        gallery {
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        highlighted_specification_label
        highlighted_specification_value
        item_number {
          text
        }
        meta_description
        minimum_order_quantity
        order_in_lots_of
        recycle_and_reuse
        related_products_dropdown {
          product {
            id
            document {
              ... on PrismicProduct {
                id
                data {
                  title {
                    text
                  }
                  highlighted_specification_value
                  highlighted_specification_label
                }
              }
            }
          }
        }
        related_products_list {
          product {
            document {
              ... on PrismicProduct {
                id
                data {
                  title {
                    text
                  }
                  tag
                  highlighted_specification_value
                  gallery {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
        specifications {
          label
          value
        }
        tag
        title {
          text
        }
        title_tag
        kind {
          document {
            ... on PrismicKind {
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
