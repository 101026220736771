import React from "react";
import styled from "styled-components";
import { mainBlue, mainRed, mainWhite, mainYellow, screen } from "../variables";

const Wrapper = styled.span`
  background: ${(props) => props.bgColour};
  color: ${(props) => props.textColour};

  padding: 8px 8px 5px 8px;
  text-transform: uppercase;
  font-size: 0.65rem;
  display: table;
  margin: 0 0 11px 0;
  @media ${screen.xsmall} {
    position: absolute;
    top: -8px;
    right: -100px;
    display: inline-block;
    margin: 0;
  }
`;

const Tag = ({ label }) => {
  let textColour;
  let bgColour;

  if (label === "Best seller") {
    textColour = mainBlue;
    bgColour = mainYellow;
  }

  if (label === "Out of stock") {
    textColour = mainWhite;
    bgColour = mainRed;
  }

  const isTag = label !== "None" && label !== "New product";

  return isTag ? (
    <Wrapper textColour={textColour} bgColour={bgColour}>
      {label}
    </Wrapper>
  ) : null;
};

export default Tag;
